.Wallpaper {
    background-position: center;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // opacity: 0.8;
    z-index: -1;
  }
@-webkit-keyframes blink{
    50% {background-color:transparent;color:white}
}
  
@-moz-keyframes blink{
    50% {background-color:transparent;color:white}
}

@keyframes blink{
    50% {background-color:transparent;color:white}
}

.Terminal-Body{
    background-color: rgba(37,42,51,0.96);
    width: 100%;
    height: 100%;
    color: #eee;
    font-size: 16px;
    font-family: 'Fira Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
    // opacity: 0.95;
    overflow-y: auto;
    overflow-x: hidden;
    div{
        margin: 5px;
        white-space: pre-wrap;
        width: 100%;
        word-break: break-all;
        display: flex;
        flex-flow: wrap;
    }

    &::-webkit-scrollbar {
        width:5px;
        height:5px;
        background-color:#f5f5f5;
        background-color:var(--bg-color-1)
    }

    &::-webkit-scrollbar-thumb{
        background-color:#f5f5f5;
        border-radius:10px;
        -webkit-box-shadow:inset 0 0 6px rgba(#f5f5f5,.3);
        background-color:var(--fg-color-1)
    }

    .terminal-input{
        .cursor{
            border: none;
            margin: 0;
            background-color: #ffff;
            color:#252a33;
            -webkit-animation: blink 1s step-end 1s infinite;
            -moz-animation: blink 1s step-end 1s infinite;
            animation: blink 1s step-end 1s infinite;
        }


    }

    .command-help{
        margin: 3px 0px 3px 5px;        
        display: flex;
        flex-wrap: wrap;
        .command-name{
            width: 100px;
            color: chartreuse;
            flex-basis: 100px;
            flex-grow: 0;
        }
        .command-explain{
            flex-basis: 0;
            flex-grow: 1;
        }
    }


    .mobile-terminal-input{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        caret-color: transparent;
        -webkit-touch-callout: none; 
        -webkit-user-select: none !important; 
        -moz-user-select: -moz-none !important;
        -ms-user-select: none !important;
        user-select: none !important;
    }

    .terminal-output-ls{
        margin: 15px;
        font-weight: bold;
    }

    .Dir {
        color: #4ed6ff;
        font-weight: 700;
    }

    .App,.SymbolicLink {
        color: chartreuse;
    }

    .LinkFile{
        color: #ac7becf6;
    }

}


@import "../../common/constants";

.Window {
  position: absolute;
  background-color: rgba(255,255,255,0.5);
  box-shadow: 0 0 48px rgba(black, .3);
  border-radius: 10px;
  display:  inline-flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  pointer-events: auto;
  transition: top $anim-duration,
  left $anim-duration,
  width $anim-duration,
  height $anim-duration,
  box-shadow $anim-duration,
  transform $anim-duration;
  animation: $anim-duration opening;

  @keyframes opening {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.closing {
    animation: $anim-duration forwards closing;

    @keyframes closing {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  box-shadow: 0 0 48px rgba(black, .4);

  .toolbar {
    .contents {
      .interceptor {
        display: none;
      }
    }
  }

  &.moving,
  &.resizing {
    transition: none;

    .contents {
      .interceptor {
        display: block;
      }
    }
  }

  &.no-toolbar {
    .toolbar {
      flex: 1;
      border-bottom: none;
      background: none;

      .title-container {
        .name {
          color: $color-light;
        }
      }
    }

    .content {
      position: absolute;
      top: $toolbar-height;
      bottom: 0;
      width: 100%;
      pointer-events: none;
      box-sizing: border-box;
    }
  }

  &.maximized {
    top: 0 !important;
    left: 0 !important;
    // height: calc(100% - #{$taskbar-height}) !important;
    height: calc(100%) !important;
    width: 100vw !important;
    transition: top $anim-duration,
    left $anim-duration,
    width $anim-duration,
    height $anim-duration,
    box-shadow $anim-duration,
    transform $anim-duration;
    animation: $anim-duration opening;
  }

  &.minimized {
    top: 100vh !important;
    transform: scale(0);
  }

  .toolbar {
    // background-color: $color-light;
    // box-shadow: 0px -2px 0px 0px #cccccccc inset;

    background-color: rgb(229, 231, 235);
    box-shadow: 0px -2px 0px 0px rgba(226, 233, 234, 0.8) inset;

    // display: flex;
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: minmax($toolbar-height, auto) auto;
    flex-direction: row;
    overflow: hidden;
    // height: 29px;
    height: fit-content;
    min-height: $toolbar-height;
    position: relative;
    // align-items: center;

    .info-container{
      display: flex;
      align-items: center;
      // padding: 0.3rem 0.2rem;
      padding: .3rem;
      box-sizing: border-box;
      width: 100%;
      justify-content: center;
      position: absolute;

      .image{
        margin-right: 5px;
        display: flex;
        align-items: center;
        .Icon{
          width: 20px;
          height: 20px;
          background-size: cover;
        }
      }
      .title{
        .icon {
          @include icon(1.2rem);
          margin-right: .4rem;
        }
  
        .name {
          font-size: $font-size-small;
          color: #444;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          min-width: 0;
        }
      }
    }
    
    .button-container {
      position: relative;
      left: 0;
      display: flex;
      height: 100%;
      z-index: 1;
      
      .button {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        text-decoration: none;
        color: rgb(0, 0, 0);
        font-size: medium;
        // padding: 8px;
        padding: 0 0.3em;
        // border-radius: 4pt;
        // margin: 0px 3px 0 3px;
        transition: background-color $anim-duration;


        height: 100%;
        object-fit: contain;
        img {
          // height: 55%;
          height: 16px;
        }
        &:hover{
          .normal{
            display: none;
          }
          .hover{
            display: block;
          }
        }
        .hover{
          display: none;
        }


        &:last-child {
          margin-right: 0;
        }

        &:active {
          border-width: 2px;
        }

        &:hover{
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }



    .tab-container {
      align-self: stretch;
      margin-left: 5.8rem;
      display: flex;
      align-items: flex-end;
      height: $toolbar-height;

      .tab {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        height: 2rem;
        background-color: $color-light;
        margin-right: 1rem;
        max-width: 12rem;
        padding: 0 .5rem;
        transition: background-color $anim-duration;
        cursor: pointer;

        .icon {
          @include icon(1.2rem);
          margin-right: .4rem;
        }

        .name {
          flex: 1;
          font-size: $font-size-small;
          color: #444;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 0;
          margin-right: .4rem
        }

        .close {
          position: relative;
          color: #888;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color $anim-duration;
          cursor: pointer;

          &:after {
            content: '×';
          }

          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: .8rem;
            height: .8rem;
            border-radius: .8rem;
            box-sizing: border-box;
            transition: background-color $anim-duration;
          }

          &:hover {
            color: #CCC;

            &:before {
              background-color: #FC615D;
            }
          }
        }

        &:before,
        &:after {
          position: absolute;
          content: '';
          top: 0;
          width: 0;
          height: 0;
          border-bottom: 2rem solid $color-light;
          transition: border-color $anim-duration;
        }

        &:before {
          left: -1rem;
          border-left: 1rem solid transparent;
        }

        &:after {
          right: -1rem;
          border-right: 1rem solid transparent;
        }

        &.active {
          z-index: 1;
          background-color: #CCC;

          &:before,
          &:after {
            border-bottom-color: #CCC;
          }
        }
      }
    }
  }

  .contents {
    position: relative;
    flex: 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    iframe {
      border: 0px
    }

    .interceptor {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
    }
  }

  $border-width: 4px;

  .border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.border-top,
    &.border-bottom {
      height: $border-width;
      cursor: ns-resize;
    }

    &.border-top {
      bottom: auto
    }

    &.border-bottom {
      top: auto;
    }

    &.border-left,
    &.border-right {
      width: $border-width;
      cursor: ew-resize;
    }

    &.border-left {
      right: auto;
    }

    &.border-right {
      left: auto;
    }

    &.border-top.border-left,
    &.border-bottom.border-right {
      cursor: nwse-resize;
    }

    &.border-top.border-right,
    &.border-bottom.border-left {
      cursor: nesw-resize;
    }
  }
}

.mobile .Window {
  top: 0 !important;
  left: 0 !important;
  height: calc(100% - #{$taskbar-height}) !important;
  width: 100vw !important;
}
@import "../../common/constants";

.Taskbar {
    position: relative;
    height: $taskbar-height;
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    justify-content: center;
    //   background-color: rgba(black, .2);
    background-color: rgba(51, 51, 51, 0.6);
    font-size: $font-size-normal;
    color: #ffff;
    font-family: NotoSansKR, sans-serif;
    font-weight: 550;
    z-index: 9999999999999999;
    
    &::before{
        content: " ";
        position: absolute;
        inset: 0;
        backdrop-filter: saturate(180%) blur(40px);
        z-index: -1;
    }

    .MenuIcon{
        position: relative;
        display: flex;
        align-items: center;
        text-align: center; 
        padding: 0 10px 0 10px;
        height: 100%;
        width: 25px;
        @include hoverable;
    }

    .UserName{
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        // font-weight: bold;
        padding: 0 10px 0 10px;
        width: fit-content;
        // font-weight: bold;
        @include hoverable;
    }

    .label {
        padding: 0 1rem;
        position: absolute;
        align-items: center;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        white-space: nowrap;

        &.label-profile {
            @include hoverable;

            .avatar {
                height: 2rem;
                width: 2rem;
                margin-right: .5rem;
                border-radius: .2rem;
                background-size: cover;
                background-position: center;
            }
        }


        .name {
            font-size: $font-size-small;
            color: white;
        }
    }

    .shortcut-container {
        flex: 1;
        display: flex;
        overflow-x: auto;
        justify-content: left;

        .shortcut {
            @include hoverable;
            position: relative;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: flex-start;
            overflow: hidden;
            max-width: 0;
            opacity: 0;
            transition: max-width $anim-duration,
                opacity $anim-duration,
                background-color $anim-duration;

            .icon {
                @include icon(2.4rem);
                margin: auto 0.5rem;
                background-position: center;
                background-size: cover;
            }
            
            .windows{
                height: 2rem;
                width: 2rem;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 3.5px;
                left: 50%;
                border: solid 1.3px white;
                background-color: white;
                opacity: 0;
                transform: translate(-50%, 0%);
                transition: opacity $anim-duration;
                border-radius: 10px;
                width: 15%;
                transition: .6s;
            }

            &:hover{
                &:after{
                    width: 30%;
                }
            }

            &.active {
                max-width: 4.2rem;
                opacity: 1;

                &:after {
                    opacity: 1;
                }
            }

            &.pinned {
                max-width: 4.2rem;
                opacity: 1;
            }
        }
    }

    .Date{
        @include hoverable;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
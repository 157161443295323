
.FileManager-Body{
    *{
        &::-webkit-scrollbar-track{
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &::-webkit-scrollbar {
            width:0px;
            height:5px;
            
            // background-color:#f5f5f5;
            // background-color:var(--bg-color-1);
            // -webkit-box-shadow: inset 0 0 6px rgba(245, 245, 245, 0.3);
        }
    
        &::-webkit-scrollbar-thumb{
            background-color:#f5f5f5;
            border-radius:10px;
            -webkit-box-shadow:inset 0 0 6px  rgba(183, 186, 197, 0.6);
            // background-color:var(--fg-color-1)
        }   
        font-size: 16px;
        font-family: "Fira Mono", Consolas, Menlo, Monaco, "Courier New", Courier, monospace;
    }
    
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;

    color: white;
    padding: 15px 15px 15px 15px;
    background-color: rgba(56, 58, 65, 0.9);

    .SideBar{
        width: fit-content;
        height: initial;
        text-align: left;
        flex-shrink: 0;
        padding: 5px 5px 0px 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .FileManager-Search{
            width: 100%;
            margin: 0 0 10px 0;
            box-sizing: border-box;
            border-radius: 10px;
            background: #2f3437;
            border: 2px solid #747379;
            outline: none;
            text-align: left;
            color: white;
            padding: 5px 2px 5px 5px;
            font-weight: bold;
            &:focus{
                border: 2px solid cornflowerblue;
            }
        }

        .SideBar-Group{
            height: fit-content;
            overflow: scroll;
            background: #2f3437;
            border-radius: 20px;
            padding: 10px 0px 10px 10px;
            
            .Directory-Group{
                padding: 10px 0 10px 0;
                .Group-Title{
                    color:rgb(224, 224, 224);
                    font-weight: bold;
                }
    
                .item{
                    padding: 5px 5px 5px 5px;
                    box-sizing: border-box;
                    display: flex;

                    .ObjectName{
                        padding-left: 10px;
                        width: 110px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
    
                .focused{
                    // background-color: white;
                    // color: black;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    // background: #2f3437;
                    background-color: rgba(66,66,74,.9);
                }
    
                .FileManager-Directory{
                    // width: 100%;
                    // width: fit-content;
                    padding-left: 17px;
                    
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    position: relative;
                    .Arrow{
                        position: absolute;
                        transform: translate(-50%);
                        left: 10px;
                        top: 7px;
                    }
                    svg{
                        text-align: center;
                        vertical-align: middle;
                    }
                }
    
            }
        }

        // .HomeDir{
        //     .FileManager-Directory{
        //         margin-left: 3px !important;
        //     }
        // }
    }

    .Contents{
        height: 100%;
        width: 100%;
        // background-color: rgba(255, 255, 255);
        background: #2f3437;
        color: white;
        border-radius: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, max-content));
        grid-gap: 16px;
        
        align-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 25px 10px 25px 10px;
        box-sizing: border-box;
        position: relative;
        justify-content: left;

        @media (max-width: 500px){
            justify-content: center;
        }


        .Directory-Path{
            position: absolute;
            left:50%;
            top: 10px;
            transform: translate(-50%);
            border-radius: 20px;
            padding-left: 10px;
            box-sizing: border-box;

            // left: 50%;
            // right: 50%;
            line-height: 22px;
            height: 22px;
            width: 98%;
            background-color: rgb(65 67 75);
            color: white;
            text-align: left;
            border: 0;
            outline: none;

            &:focus{
                border: 1;
            }
        }
        

        div{
            height: fit-content;
            width: 100px;
            text-align: center;
        }

        .FileManager-InnerFile{
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 20px;
            .Icon{
                height: 80px;
                width: 80px;
                // width: 100%;
                filter: drop-shadow(0 0 0.21875rem rgba(0, 0, 0, 0.5));
                background-position: center;
                background-size: cover;
            }

            &:hover {
                // background-color: rgba(0, 0, 0, 0.2);
                background-color: rgba(66,66,74,.9);
                color: white;
            }
        }
        
        .FileManager-Empty{
            position: absolute;
            left:50%;
            top: 40%;
            transform: translate(-50%);
            padding: 20px;
            height: fit-content;
            width: 100%;
            text-align: center; 
            font-weight: bold; 
            box-sizing: border-box;
        }
    }
}